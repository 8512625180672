import React from "react";
import Layout from "../components/Layout";
import HandSurgery from "../components/HandSurgery";

const HandSurgeryPage = ({ data }) => (
  <Layout>
    <HandSurgery data={data} />
  </Layout>
);

export default HandSurgeryPage;

export const query = graphql`
  query {
    xray: file(relativePath: { eq: "mj-hand-xray.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
