import React from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { styleBackgroundImage } from "../../components/Section/section.style";
import { Row, Col } from "react-styled-flexboxgrid";
import { Primary, Alternate } from "../../components/Section";
import { HrefButtonGhostInverse } from "../../components/Button";

const BackgroundSection = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-palm-trees.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  ${styleBackgroundImage};
`;
const HandSurgery = ({ data }) => (
  <section>
    <StyledBackgroundSection centered>
      <Row>
        <Col xs={12}>
          <h1>Hand Surgery</h1>
          <p>
            “Where the spirit does not work with the hand, there is no art.”
            Leonardo da Vinci
          </p>
        </Col>
      </Row>
    </StyledBackgroundSection>
    <Primary centered>
      <Row>
        <Col xs={12}>
          <h2>The Spirit of the Hand</h2>
          <p>
            “The happiest surgeons I met and the oldest surgeons whom I met were
            hand surgeons.” Dr. Michael J.
          </p>
          <p>
            Whether a patient was two or ninety-two, treating their hands
            clearly made a difference in their mind, body and spirit.
          </p>
          <p>
            {" "}
            “In the absence of any other proof, the thumb alone would convince
            me of God’s existence.” - Isaac Newton.
          </p>
        </Col>
      </Row>
    </Primary>

    <Alternate>
      <Row>
        <Col xs={12} md={6}>
          <h2>Telemedicine + Comprehensive Hand Care</h2>
          <p>
            MJMD Global Group continues to make a difference to our patients as
            a management team for surgeons and health care professionals who
            believe in the spirit of free market medicine and transparency.
            Learn more about Dr. Michael J and The Joyner Hand, PA.
          </p>
          <br />
          <HrefButtonGhostInverse href="//joynermd.com" target="_blank">
            Dr. Michael J and The Joyner Hand
          </HrefButtonGhostInverse>
        </Col>
        <Col xs={12} md={6}>
          <Img fluid={data.xray.childImageSharp.fluid} alt="Hand Xray" />
        </Col>
      </Row>
    </Alternate>
  </section>
);

export default HandSurgery;
